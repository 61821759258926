<template>
  <cz-collection :class="customClass" :id="elementId" find-route-name="Root" :include-route-name-list="includeRouteNameList">
    <section class="registration-search-container">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item class="search-input" prop="numberValue">
          <p>证件号码</p>
          <el-input placeholder="请输入证件号码" v-model="ruleForm.numberValue"></el-input>
        </el-form-item>
        <el-form-item class="search-input search-input-captcha" prop="captchaCodeValue">
          <p>验证码</p>
          <el-input placeholder="请输入验证码" v-model="ruleForm.captchaCodeValue"></el-input>
          <fox-captcha v-if="machineCheckShow" type="digital" :height="46" ref="foxCaptcha"></fox-captcha>
        </el-form-item>
        <div class="search-button">
          <!--        <el-button type="primary" @click="searchResFn()">查询</el-button>-->
          <el-button type="primary" @click="reSendCode()">查询</el-button>
          <!--          <c-pre>isResultFlag={{ isResultFlag }}</c-pre>-->
        </div>
      </el-form>
    </section>
    <!-- 结果 -->
    <section v-if="isResultFlag" class="registration-search-result-container">
      <div class="result-race-detail-box">
        <h2>{{ resultData.raceName }}</h2>
        <div class="detail-list-box">
          <div class="detail-item" v-for="(item,index) in centerList" :key="index">
            <p class="title">{{ item.componentName }}</p>
            <div class="result-image" v-if="item.componentCode == 'ZtUploadImage'">
              <el-image v-if="e" v-for="(e,i) in item.answer" :key="i" :src="normalizeImageUrl(e)" :preview-src-list="srcList" alt=""></el-image>
              <p v-else>-</p>
            </div>
            <p v-if="item.componentCode != 'ZtUploadImage'" class="result">{{ item.answer || '-' }}</p>
          </div>
          <!--不动-->
          <div class="detail-item">
            <p class="title">参赛项目</p>
            <p class="result">{{ resultData.itemName || '-' }}</p>
          </div>
          <div class="detail-item">
            <p class="title">订单状态</p>
            <p class="result">{{ resultData.orderStatus | filterOrderStatus }}</p>
          </div>
          <div class="detail-item" v-if="resultData.entryNumber">
            <p class="title">参赛号码</p>
            <p class="result">{{ resultData.entryNumber || '-' }}</p>
          </div>
          <!--不动-->
          <!--          <div class="detail-item">-->
          <!--            <p class="title">审核状态</p>-->
          <!--            <p class="result">{{ resultData.auditStatus | filterAuditStatus }}</p>-->
          <!--          </div>-->
          <!--不动-->
          <div class="detail-item">
            <p class="title">抽签状态</p>
            <p class="result">{{ resultData.drawStatus | filterDrawStatus }}</p>
          </div>
        </div>
      </div>
      <p class="img-tip" v-if="false">下载请点击右键图片另存为</p>
      <div class="result-race-image" v-if="false">
        <img src="../../../assets/img/home/registration-res.png" alt="">
      </div>
    </section>
  </cz-collection>
</template>

<script type="text/ecmascript-6">
import store from '@/store'

// import {mapMutations, mapActions, mapState, mapGetters} from 'vuex';
import machineCheck  from './components/machineCheck'
import SearchExtends from '../search-extends.js'
import czApi         from '@/api/cz'
import GlobalConfig  from '@/plugins/globalConfig.js'
import Global        from '@/mixins/global.js'
import FoxCaptcha    from 'fox-captcha'
import {cloneDeep}   from 'lodash'

export default {
  extends   : SearchExtends,
  mixins    : [GlobalConfig, Global],
  components: { machineCheck, FoxCaptcha },
  name      : 'RegistrationSearch',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      ruleForm        : {
        // numberValue     : null,//证件号码
        numberValue     : '',//证件号码
        captchaCodeValue: null,//验证码
      },
      machineCheckShow: true,
      isResultFlag    : false,
      nc_DataArr      : {},
      resultData      : {},
      centerList      : [],
      srcList         : [],
      countryList     : [],//国家信息
      rules           : {
        numberValue     : [
          { required: true, message: '请填写证件号码', trigger: 'blur' },
        ],
        captchaCodeValue: [
          { required: true, message: '请填写验证码', trigger: 'blur' },
        ],
      },
    }
  },
  watch   : {},
  computed: {
    elementId() {return `RegistrationSearch_${this._uid}`},
    customClass() {return ['RegistrationSearch', this.className]},
  },
  methods : {
    //获取国家国籍信息
    getCountryListApi() {
      // czApi.changzhengBasicCenterApiIsoCountryListAll().then(res => {
      //   console.log(res)
      //   this.countryList = res.data
      // })
      this.countryList = cloneDeep(store.state.components.countryList)

    },
    // 滑块验证成功
    reSendCode() {
      this.$refs['ruleForm'].validate((valid) => {
        let that = this
        if (valid) {
          if (this.$refs.foxCaptcha.validate(this.ruleForm.captchaCodeValue)) {
            this.resultData = {}
            this.centerList = []
            // this.isResultFlag = true
            czApi.changzhengWebsiteCenterApiWebsiteSearchRegister({ cardNumber: this.ruleForm.numberValue, raceId: this.$store.getters.raceId }).then((res) => {
              // czApi.changzhengWebsiteCenterApiWebsiteSearchRegister({cardNumber:this.numberValue,raceId:1000026026}).then((res)=>{
              console.log(res)
              if (res.data) {
                ////////////////////////////////////////////////////////////////////////////
                // this.machineCheckShow = false
                let foxCaptcha = this.$refs.foxCaptcha
                foxCaptcha.init()
                // ////////////////////////////////////////////////////////////////////////////

                this.resultData = res.data
                let centerList  = res.data.detailRegistrationInfo.innerComponentList
                // let centerList  = mockData.data.innerComponentList
                centerList      = centerList.map(item => {
                  item.customContent = item.answer
                  // this.$set(item, 'customContent', '')
                  if (item.componentCode == 'ZtCheckbox' || item.componentCode == 'ZtRadio') {
                    console.log('item.answer = ', item.answer)
                    if (item.answer) {
                      let customSelectedOptionValueList = item.answer.split(',').filter(item => item.trim()).map(item => `${item}`)
                      console.log('customSelectedOptionValueList = ', customSelectedOptionValueList)
                      item.customSelectedOptionValueList = customSelectedOptionValueList
                    }
                    else {
                      item.customSelectedOptionValueList = ['-1']
                    }
                  }
                  ///
                  else if (item.componentCode == 'ZtUploadImage') {
                    item.customContent = item.answer
                    if (item.answer) {
                      item.customImageList = item.answer.split(',').filter(item => item.trim()).map(item => ({ url: item }))

                      if (item.customImageList == 1) {
                        item.customImageList.push({ url: '' })
                        item.customImageList.push({ url: '' })
                      }

                      if (item.customImageList == 2) {
                        item.customImageList.push({ url: '' })
                      }

                      if (item.customImageList == 3) {
                        //
                      }
                    }
                    else {
                      item.customImageList = [{ url: '' }, { url: '' }, { url: '' }]
                    }
                  }
                  // else if (item.componentCode == 'ZtPCA') {
                  else if (item.componentCode.startsWith('ZtPCA')) {
                    item.customContent = item.answer
                    if (item.answer) {
                      const pcaOptions                               = JSON.parse(item.answer)
                      const { provinceCode, cityCode, districtCode } = pcaOptions
                      item.customPcaList                             = [provinceCode, cityCode, districtCode]
                    }
                    else {
                      item.customPcaList = []
                    }
                  }
                  else {
                    if (item.answer) {
                      // item.customContent = item.answer
                      this.$set(item, 'customContent', item.answer)
                    }
                    else {
                      // item.customContent = ''
                      this.$set(item, 'customContent', '')
                    }
                  }
                  return item
                })
                centerList.forEach((item) => {
                  if (item.componentCode == 'ZtRadio' || item.componentCode == 'ZtRadioCombineUrl') {
                    let a = item.optionList.find((itema) => {
                      return itema.optionValue == item.answer
                    })
                    if (a) {item.answer = a.optionName}
                  }
                  if (item.componentCode == 'ZtCheckbox') {//多选框
                    let arr     = item.answer.split(',')
                    item.answer = ''
                    arr.forEach((arritem) => {
                      let a = item.optionList.find((itema) => {
                        return itema.optionValue == arritem
                      })
                      if (a) {item.answer += a.optionName}
                    })

                  }
                  if (item.componentCode == 'ZtUploadImage') {
                    item.answer = item.answer.split(',')
                    item.answer.forEach((i) => {
                      this.srcList.push(that.normalizeImageUrl(i))
                    })
                    console.log(item.answer, this.srcList)
                  }
                  if (item.componentCode == 'ZtCountrySingle') {
                    let b = this.countryList.find((itemb) => {
                      return itemb.itemCode == item.answer
                    })
                    if (b) {item.answer = b.itemName}
                  }
                  if (item.componentCode == 'ZtPCACombineUploadImg' && item.answer || item.componentCode == 'ZtPCA' && item.answer) {
                    let arr     = []
                    arr[0]      = JSON.parse(item.answer).provinceCode
                    arr[1]      = JSON.parse(item.answer).cityCode
                    arr[2]      = JSON.parse(item.answer).districtCode
                    item.answer = this.getProvinceAndCityAndDistrict(arr)
                  }
                  if (item.componentCode == 'ZtPastEvents') {
                    if (JSON.parse(item.answer).pastRaceSource == 1) {
                      item.answer = JSON.parse(item.answer).pastRaceName
                    }
                    else {
                      item.answer = '否'
                    }
                  }
                })

                console.log('centerList = ', centerList)
                this.centerList = centerList

                this.isResultFlag = true
                this.$message({
                  showClose: true,
                  message  : '报名信息查询成功！',
                  type     : 'success',
                })
              }
              else {
                this.isResultFlag = false
                this.$alert('未查询到您的报名信息，请确认证件信息后再试', '暂无数据', {
                  confirmButtonText: '确定',
                  callback         : action => { },
                })
              }
            }).catch(err => {
              this.isResultFlag = false
              console.log(err)
            })
          }
          else {
            this.isResultFlag = false
            this.$alert('验证码校验失败', '提示', {
              confirmButtonText: '确定',
              callback         : action => { },
            })
          }
        }
        else {
          return false
        }
      })
    },
  },
  filters : {
    filterGender(val) {
      if (val == 1) {
        return '男'
      }
      else if (val == 2) {
        return '女'
      }
      else if (val == 3) {
        return '保密'
      }
      else {
        return '-'
      }
    },
    filterAuditStatus(val) {
      if (val == 0) {
        return '-' // 无需审核
      }
      else if (val == 1) {
        return '待审核'
      }
      else if (val == 2) {
        return '审核通过'
      }
      else if (val == 3) {
        return '审核拒绝'
      }
      else {
        return '-'
      }
    },
    filterOrderStatus(val) {
      if (val == 1) {
        return '待付款'
      }
      else if (val == 2) {
        return '已付款'
      }
      else if (val == 3) {
        return '已退款'
      }
      else if (val == 4) {
        return '已取消'
      }
      else if (val == 10) {
        return '退款审核中'
      }
      else {
        return '-'
      }
    },
    filterDrawStatus(val) {
      if (val == 0) {
        return '-' // 无需抽签
      }
      else if (val == 1) {
        return '待抽签'
      }
      else if (val == 2) {
        return '未中签'
      }
      else if (val == 3) {
        return '已中签'
      }
      else {
        return '-'
      }
    },
    filterCardType(val) {
      if (val == 0) {
        return '二代身份证'
      }
      else if (val == 1) {
        return '护照'
      }
      else if (val == 2) {
        return '港澳通行证'
      }
      else if (val == 3) {
        return '台湾通行证'
      }
      else if (val == 4) {
        return '香港永久性居民身份证'
      }
      else if (val == 5) {
        return '士官证'
      }
      else if (val == 6) {
        return '注册号'
      }
      else {
        return '-'
      }
    },
  },
  created() {
    // document.documentElement.scrollTop = 0
    this.getCountryListApi()
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
// registration-search
// @import "RegistrationSearch.scss"
.RegistrationSearch {
  background : #F1F1F1;
  padding    : 0 100px 70px;

  .registration-search-container {
    width          : 1200px;
    margin         : 0 auto;
    background     : #ffffff;
    box-sizing     : border-box;
    padding        : 100px 0;
    display        : flex;
    flex-direction : column;
    align-items    : center;

    ::v-deep .search-input .el-form-item__content {
      display         : flex;
      justify-content : center;
      align-items     : center;

      p {
        white-space  : nowrap;
        font-size    : 16px;
        font-weight  : 400;
        color        : #65697E;
        line-height  : 22px;
        margin-right : 12px;
      }
    }

    ::v-deep .search-input .el-input .el-input__inner {
      width         : 541px;
      height        : 46px;
      background    : #FFFFFF;
      border-radius : 2px;
      border        : 1px solid #E6EBF7;
    }

    ::v-deep .search-input .el-form-item__error {
      margin-left : 78px;
    }

    .search-input-captcha {
      .el-input {
        margin : 0 22px 0 16px;
      }

      ::v-deep .el-input .el-input__inner {
        width : 415px;
      }
    }

    .search-button {
      margin-top : 30px;
      text-align : center;

      .el-button {
        width         : 120px;
        height        : 44px;
        background    : #CF000D;
        border-radius : 2px;
      }
    }
  }

  .registration-search-result-container {
    background : #ffffff;
    overflow   : hidden;
    padding    : 0 30px 30px;

    .result-race-detail-box {
      width         : 100%;
      //height        : 499px;
      background    : #F9F9F9;
      border-radius : 6px;
      padding       : 60px 160px;
      box-sizing    : border-box;

      h2 {
        font-size   : 26px;
        font-weight : 500;
        color       : #181921;
        line-height : 37px;
        text-align  : center;
        margin      : 0 0 30px 0;
      }

      .detail-list-box {
        display         : flex;
        justify-content : space-between;
        flex-wrap       : wrap;

        .detail-item {
          display     : flex;
          align-items : center;
          margin-top  : 30px;

          p {
            margin : 0;
          }

          .title {
            width        : 80px;
            font-size    : 16px;
            font-weight  : 400;
            color        : #65697E;
            line-height  : 22px;
            margin-right : 30px;
          }

          .result {
            width       : 280px;
            font-size   : 16px;
            font-weight : 400;
            color       : #14151E;
            line-height : 22px;
            word-break  : break-all;
            white-space : normal;
            word-wrap   : break-word;
          }

          .result-image {
            width : 280px;

            .el-image {
              height       : 80px;
              margin-right : 10px;
            }
          }
        }
      }

    }

    .img-tip {
      font-size   : 16px;
      font-weight : 400;
      color       : #65697E;
      line-height : 22px;
      margin      : 30px 0;
      text-align  : right;
    }

    .result-race-image {
      img {
        width : 100%;
      }
    }
  }

}
</style>
